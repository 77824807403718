import { BusinessType } from '@/config/common'
import { eventTrackingByBusiness } from '../decorator'
import {
  ISignatureEvent,
  subscribeBrowserSignature,
  subscribeContractFilesPageClickNav,
  subscribeSignature
} from '../eventbus'
import { eventTracking } from '../EventTracking'

const contractListPageCode = 'ea_contract_list_page' // 合同列表
const importantTermPageCode = 'ea_contract_clause_read_page' // 重要条款
const contractFilesPageCode = 'ea_contract_detail_page' // 文件列表
const contractSignaturePageCode = 'ea_contract_sign_page' // 手写签署页

class EContractingEventTracking {
  startTime = 0
  @eventTrackingByBusiness(BusinessType.E_CONTRACTING)
  setGlobalProperties(trackingData: ITrackingData) {
    eventTracking.setCommonGlobalProperties(trackingData)
    this.subscribe()
  }
  browserContractListPage() {
    //DM_合同列表页
    eventTracking.browserPage(contractListPageCode)
  }

  contractListClickRead() {
    // EA_合同列表页_开始阅读合同及文档点击
    eventTracking.clickEvent('ea_contract_list_read_clk', contractListPageCode)
  }

  browserImportantTerm() {
    // ea_contract_clause_read_page	EA_重要条款阅读页
    this.startTime = new Date().getTime()
    eventTracking.browserPage(importantTermPageCode)
  }

  importantTermClickConfirm() {
    // EA_重要条款阅读页_阅读并确认点击
    const duration = new Date().getTime() - this.startTime
    eventTracking.clickEvent('ea_contract_clause_confirm_clk', importantTermPageCode, {
      action_duration: duration / 1000
    })
  }

  browserContractFilesPage() {
    //	EA_合同详情页
    this.startTime = new Date().getTime()
    eventTracking.browserPage(contractFilesPageCode)
  }

  contractFilesPageClickNav(contractName: string) {
    // EA_合同详情页_导航点击
    eventTracking.clickEvent('ea_contract_detail_nav_clk', contractFilesPageCode, {
      button_name: contractName
    })
  }

  contractFilesPageClickRead() {
    //	EA_合同详情页_我已完整阅读并同意继续点击
    const duration = new Date().getTime() - this.startTime
    eventTracking.clickEvent('ea_contract_detail_agree_clk', contractFilesPageCode, {
      action_duration: duration / 1000
    })
  }

  browserSignaturePage() {
    //DM_电子签署页
    eventTracking.browserPage(contractSignaturePageCode)
  }

  signature(event: ISignatureEvent) {
    //DM_电子签署页_签署结果
    delete event.isManual
    eventTracking.otherEvent('ea_contract_sign_result', contractSignaturePageCode, event)
  }

  @eventTrackingByBusiness(BusinessType.E_CONTRACTING)
  subscribe() {
    subscribeBrowserSignature(this.browserSignaturePage)
    subscribeSignature(this.signature)
    subscribeContractFilesPageClickNav(this.contractFilesPageClickNav)
  }
}

export const eContractingEventTracking = new EContractingEventTracking()
