export enum BusinessType {
  DAF = 'DAF',
  CSS = 'CSS',
  E_CONTRACTING = 'E_CONTRACTING',
  DM = 'DM',
  CREDIT_LIMIT = 'CREDIT_LIMIT'
}

export const domains: { [key: string]: string } = {
  dev: 'esign-dev.cndc.corpintra.net',
  int: 'esign-int.mercedes-benz-finance.com.cn',
  uat: 'esign-uat.mercedes-benz-finance.com.cn',
  production: 'esign.mercedes-benz-finance.com.cn'
}

export const scaleOptions = {
  defaultScale: 1, // 默认缩放级别
  importantTermScale: 4, // 重点条款缩放级别
  MIN_SCALE: 0.6,
  MAX_SCALE: 4
}
